
// import axios from 'axios';
// const api_url = process.env.VUE_APP_API_URL;
// export const apiServices = {
//     callApi
// }

// function callApi(method, url, data, multipart = false, token) {
//     let config = {
//         method: method,
//         url: api_url + url,
//         data: data,
//     };
//     if (multipart) {
//         config.headers = { "content-type": "multipart/form-data" };
//     }
//     if (token) {
//         config.headers = { "Authorization": `Bearer ${token}` };
//     }
//     try {
//         let res = axios(config);
//         return res;
//     } catch (e) {
//         // return e.response
//     }
// }


import axios from "axios";
const api_url = process.env.VUE_APP_API_URL;

export const apiServices = {
  callApi,
};

function callApi(method, url, data, multipart = false, token, timeout = 90000) {
  let config = {
    method: method,
    url: api_url + url,
    data: data,
    timeout: timeout, // Set timeout for the request
  };

  if (multipart) {
    config.headers = { "content-type": "multipart/form-data" };
  }
  if (token) {
    config.headers = { Authorization: `Bearer ${token}` };
  }

  return axios(config)
    .then((response) => response)
    .catch((error) => {
      if (error.code === "ECONNABORTED") {
        return Promise.reject(new Error("Request timed out"));
      }
      return Promise.reject(error);
    });
}
