import { createApp } from 'vue'

import App from './App.vue'
import router from "./router";
import VueApexCharts from "vue3-apexcharts";
import vClickOutside from "click-outside-vue3";
import { registerScrollSpy } from 'vue3-scroll-spy';

import { vMaska } from "maska"
import i18n from "./i18n"

import BootstrapVueNext from 'bootstrap-vue-next'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import "@/assets/scss/app.scss";
import "@vueform/multiselect/themes/default.css"

// PINIA
import pinia from '@/state/pinia'

import 'sweetalert2/dist/sweetalert2.min.css';
import '@vueform/slider/themes/default.css';

import vue3GoogleLogin from 'vue3-google-login'

import Pusher from 'pusher-js';

import { initializeEcho } from './helpers/echo-service';
import { apiServices } from './helpers/api-service';

if (process.env.VUE_APP_NODE_ENV === 'production') {
    console.log = () => { };
    Pusher.logToConsole = false;
} else {
    Pusher.logToConsole = true;
}

const accessToken = localStorage.getItem('access_token');
initializeEcho(accessToken);

async function fetchGoogleClientIdAndInitializeApp() {
    try {
        const res = await apiServices.callApi('post', 'fetch-google-client-id', {}, false, null, 10000);
        if (res.data.status) {
            const googleClientId = res.data.data.google_client_id;

            // Initialize Vue app with the fetched client ID
            createApp(App)
                .use(pinia)
                .use(router)
                .use(require('vue-chartist'))
                .use(BootstrapVueNext)
                .use(VueApexCharts)
                .use(vClickOutside)
                .use(i18n)
                .use(registerScrollSpy)
                .use(vue3GoogleLogin, { clientId: googleClientId }) // Use fetched client ID here
                .directive('maska', vMaska)
                .mount('#app');
        } else {
            createApp(App)
                .use(pinia)
                .use(router)
                .use(require('vue-chartist'))
                .use(BootstrapVueNext)
                .use(VueApexCharts)
                .use(vClickOutside)
                .use(i18n)
                .use(registerScrollSpy)
                // .use(vue3GoogleLogin, { clientId: googleClientId }) // Use fetched client ID here
                .directive('maska', vMaska)
                .mount('#app');
        }
    } catch (error) {
        createApp(App)
            .use(pinia)
            .use(router)
            .use(require('vue-chartist'))
            .use(BootstrapVueNext)
            .use(VueApexCharts)
            .use(vClickOutside)
            .use(i18n)
            .use(registerScrollSpy)
            // .use(vue3GoogleLogin, { clientId: googleClientId }) // Use fetched client ID here
            .directive('maska', vMaska)
            .mount('#app');
        console.error('Error fetching Google client ID:', error);
    }
}

fetchGoogleClientIdAndInitializeApp();


// createApp(App)
//     .use(pinia)
//     .use(router)
//     .use(require('vue-chartist'))
//     .use(BootstrapVueNext)
//     .use(VueApexCharts)
//     .use(vClickOutside)
//     .use(i18n)
//     .use(registerScrollSpy)
//     .use(vue3GoogleLogin, {
//         clientId: google_client_id
//     })
//     .directive("maska", vMaska)
//     .mount('#app')

// 452190390124-9p8d3o0b6qn48j35cdorlm0vokf0fi4p.apps.googleusercontent.com // intelipanel dev
// 740054991886-r00ijdkru3klff5pr58viahqmtd4j0lg.apps.googleusercontent.com
// 381559313865-ihfktasq5ih2b48dic3d6d59nmn1sr0b.apps.googleusercontent.com // current one
// 608578407854-kfib2i7q7aetg1jfr23uejfso5091tk9.apps.googleusercontent.com - test
// 452190390124-k0ja4f04iq47nbnjrpbnjp3l1ilf33pd.apps.googleusercontent.com //staging
