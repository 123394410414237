<template>
  <router-view></router-view>

  <!-- warning modal -->
  <b-modal
    v-model="showWarningModal"
    :visible="showWarningModal"
    :clickToClose="false"
    hide-footer
    hide-header-close
    no-close-on-backdrop
    centered
    header-class="border-0"
    size="s"
  >
    <h5 style="text-align: center">Session Expiring Soon</h5>
    <p style="text-align: center">
      Your session will expire in 1 minute due to inactivity.
    </p>
    <div class="text-end mt-3">
      <b-button type="button" variant="primary">
        <span @click="extendSession">Stay Logged In</span>
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { useAuthFakeStore } from "@/state/pinia"; // Import Pinia store

export default {
  name: "App",
  data() {
    return {
      inactivityTimer: null,
      warningTimer: null,
      SESSION_TIMEOUT: 30 * 60 * 1000, // 30 minutes
      WARNING_TIME: 29 * 60 * 1000, // Show warning at 29 minutes (1 min before logout)
      // SESSION_TIMEOUT: 60 * 1000, // 1 minute
      // WARNING_TIME: 30 * 1000, // Show warning at 30 seconds
      showWarningModal: false,
    };
  },
  methods: {
    resetTimer() {
      if (!localStorage.getItem("user")) return; 
      if (this.showWarningModal) return; // if warning modal is open dont reset the timer
      // Reset both inactivity and warning timers
      clearTimeout(this.inactivityTimer);
      clearTimeout(this.warningTimer);
      this.showWarningModal = false;

      console.log("Activity Detected: Reset Timers");

      // Set warning timer (29 min)
      this.warningTimer = setTimeout(() => {
        console.log("Showing Warning Modal");
        this.showWarningModal = true;
      }, this.WARNING_TIME);

      // Set logout timer (30 min)
      this.inactivityTimer = setTimeout(() => {
        console.log("User Inactive: Logging Out...");
        this.logoutUser();
      }, this.SESSION_TIMEOUT);
    },

    extendSession() {
      console.log("Session Extended");
      this.showWarningModal = false;
      this.resetTimer();
    },

    logoutUser() {
      console.log("Logging Out User...");
      const authFake = useAuthFakeStore();
      this.showWarningModal = false;
      authFake.logout();
      localStorage.removeItem("session_expiry");
    },
    checkSession() {
      const user = localStorage.getItem("user");
      const expirationTime = localStorage.getItem("session_expiry");

      if (!user || !expirationTime) return; // Skip if no user is logged in
      if (expirationTime && Date.now() > expirationTime) {
        this.logoutUser();
      }
    },
    setSession() {
      const expirationTime = Date.now() + this.SESSION_TIMEOUT;
      localStorage.setItem("session_expiry", expirationTime);
    },
  },
  mounted() {
    this.setSession(); // Set session on page load
    this.checkSession(); // Check if session expired
    this.resetTimer(); // Start inactivity timer

    // Add event listeners to track user activity
    window.addEventListener("mousemove", this.resetTimer);
    window.addEventListener("keydown", this.resetTimer);
    window.addEventListener("click", this.resetTimer);
    window.addEventListener("scroll", this.resetTimer);
  },
  beforeUnmount() {
    // Remove event listeners when component is destroyed
    window.removeEventListener("mousemove", this.resetTimer);
    window.removeEventListener("keydown", this.resetTimer);
    window.removeEventListener("click", this.resetTimer);
    window.removeEventListener("scroll", this.resetTimer);
  },
};
</script>
