import { createWebHistory, createRouter } from "vue-router";
import routes from "./routes";
import appConfig from "../app.config.json";
import { admin_login_url } from "../constants/constant";

let role = null;
let appName = null;

// Function to fetch branding data and update favicon
async function fetchBrandingData() {
  let brandingData = localStorage.getItem("branding_data");

  if (!brandingData) {
    try {
      const response = await fetch(`${process.env.VUE_APP_API_URL}branding-details`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      });

      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

      const data = await response.json();
      brandingData = data.data;

      // Store branding data in localStorage
      localStorage.setItem("branding_data", JSON.stringify(brandingData));
    } catch (error) {
      console.error("Error fetching branding data:", error);
      brandingData = {};
    }
  } else {
    brandingData = JSON.parse(brandingData);
  }

  // ✅ Update Favicon Dynamically
  if (brandingData.favicon) {
    const favicon = document.querySelector("link[rel='icon']");
    if (favicon) {
      favicon.setAttribute("href", brandingData.favicon);
    } else {
      const newFavicon = document.createElement("link");
      newFavicon.rel = "icon";
      newFavicon.href = brandingData.favicon;
      document.head.appendChild(newFavicon);
    }
  }

  return brandingData.company_name ?? "";
}

// Fetch app name on load
fetchBrandingData().then((name) => {
  appName = name;
});

const router = createRouter({
  history: createWebHistory(),
  routes,
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { x: 0, y: 0 };
  }
});

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
  const publicPages = ["/login", "/register", "/forgot-password", admin_login_url, "/email-verification", "/reset-password"];
  const authPage = !publicPages.includes(routeTo.path);
  const loggedUser = JSON.parse(localStorage.getItem("user"));

  if (authPage && !loggedUser) {
    return next("/login");
  }
  if (loggedUser) {
    role = loggedUser.role;
  }

  next();
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
  try {
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        if (route.meta?.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            if (args.length) {
              next(...args);
              reject(new Error("Redirected"));
            } else {
              resolve();
            }
          });
        } else {
          resolve();
        }
      });
    }
  } catch (error) {
    return;
  }

  // Update page title dynamically
  document.title = `${routeTo.meta.title} | ${routeTo.path == "/ip-salogin" || role == 1 ? appConfig.title : appName}`;

  next();
});

export default router;
