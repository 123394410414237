// import Echo from 'laravel-echo';
// import Pusher from 'pusher-js';

import { initializeEcho } from "../echo-service";

export const api_url = process.env.VUE_APP_API_URL;

export const userService = {
    login,
    admin_login,
    logout,
    register,
    verifyEmail,
    forgotPassword
};

function login(email, password, token, role, type) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password, token, role, type }),
    };

    if (type === "adminascustomer") {
        localStorage.setItem("isAdmin", "1");
    } else {
        localStorage.setItem("isAdmin", "0");
    }

    const url = token != null ? `google-login` : `login`;

    return fetch(api_url + url, requestOptions)
        .then(handleResponse)
        .then(user => {
            if (user.data.accessToken) {
                let username = user.data.userDetails.name;
                localStorage.setItem('username', JSON.stringify(username));
                // Store user details and access token in local storage
                localStorage.setItem('user', JSON.stringify(user.data.userDetails));
                localStorage.setItem('baseCurrency', JSON.stringify(user.data.base_currency));
                localStorage.setItem('userimg', JSON.stringify(user.data.userimg));
                localStorage.setItem('access_token', JSON.stringify(user.data.accessToken));
                localStorage.setItem('cookie', JSON.stringify(user.data.userDetails.device_id));
                localStorage.setItem('otp', JSON.stringify(user.data.otp));

                // Initialize Laravel Echo
                initializeEcho(JSON.stringify(user.data.accessToken));
            }
            return user.data;
        });
}


// function initializeEcho(accessToken) {
//     console.log("auth url -->", process.env.VUE_APP_AUTH_URL);
//     console.log("Access Token:", accessToken);
//     if (accessToken) {
//         window.Pusher = Pusher;
//         window.Echo = new Echo({
//             broadcaster: 'pusher',
//             key: process.env.VUE_APP_WEBSOCKET_KEY,
//             cluster: 'mt1',
//             wsHost: process.env.VUE_APP_WEBSOCKET_SERVER,
//             wsPort: process.env.VUE_APP_PUSHER_PORT,
//             wssPort: process.env.VUE_APP_PUSHER_PORT,
//             forceTLS: false,
//             enabledTransports: ['ws', 'wss'],
//             disabledStats: true,
//             authEndpoint: process.env.VUE_APP_AUTH_URL,
//             channelAuthorization: {
//                 endpoint: process.env.VUE_APP_AUTH_URL,
//                 headers: {
//                     Authorization: `Bearer ${JSON.parse(accessToken)}`,
//                 },
//             },
//             auth: {
//                 headers: {
//                     Authorization: `Bearer ${JSON.parse(accessToken)}`, // Use accessToken directly
//                 },
//             },
//         });
//         console.log('Laravel Echo reinitialized after refresh:', window.Echo);

//         if (window.Echo.connector) {
//             console.log("After Echo initialization:", window.Echo.connector.options.authEndpoint);
//         } else {
//             console.warn("Echo.connector is undefined.");
//         }
//     } else {
//         console.warn('No access token found. Echo not initialized.');
//     }
// }

function admin_login(identifier, password, token, role, type) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ identifier, password, token, role, type }),
    };
    localStorage.setItem("isAdmin", "0");
    return fetch(api_url + 'admin-login', requestOptions)
        .then(handleResponse)
        .then(user => {
            if (user.data.accessToken) {
                let username = user.data.userDetails.name;
                localStorage.setItem('user', JSON.stringify(user.data.userDetails));
                localStorage.setItem('username', JSON.stringify(username));
                localStorage.setItem('userimg', JSON.stringify(user.data.userimg));
                localStorage.setItem('access_token', JSON.stringify(user.data.accessToken));
                localStorage.setItem('cookie', JSON.stringify(user.data.userDetails.device_id));
                localStorage.setItem('otp', JSON.stringify(user.data.otp));

                // Initialize Laravel Echo
                initializeEcho(JSON.stringify(user.data.accessToken));
            }
            return user.data;
        });
}

function logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('access_token');
    localStorage.removeItem('device_id');
    localStorage.clear();

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: null,
    };

    return fetch(api_url + `logout`, requestOptions)
        .then(response => {
            if (!response.ok) {
                throw new Error(`Logout failed with status: ${response.status}`);
            }
            if (response.status === 204) {
                return {};
            }
            return response.json();
        })
        .then(handleResponse)
        .catch(error => {
            console.error('Logout error:', error);
        });
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user),
    };
    return fetch(api_url + `register`, requestOptions).then(handleResponse);
}

function verifyEmail(verification_key, email, isVerify) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ verification_key, email, isVerify }),
    };
    return fetch(api_url + `mail-verification`, requestOptions).then(handleResponse);
}

function forgotPassword(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    };
    return fetch(api_url + `forgot-password`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (data.data) {
                localStorage.setItem('status', data.data.status);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
